import store from '@/store';
import { contactName } from '@/utils/general';
/**
 * get
 * @param {*} accountList
 * @param {@type {import('@m/kyb/store/state').kybState['bussinessDetails']}} businessList
 * @returns
 */
export function getAccountsGroup(
  accountList,
  businessList,
  $personalChecking = true,
  $businessChecking = true
) {
  accountList.sort((a, b) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  const accountsData = {};
  const user = store.getters['user/getPersonDetails'];
  const fullName = user ? contactName(user) : '';

  accountList.forEach((a) => {
    let businessId = a.businessId;
    let business = undefined;

    if (a.type === 'personalChecking' && $personalChecking) {
      businessId = dummyBusinessId;
      business = {
        legalName: fullName
      };
    } else if ($businessChecking) {
      business = businessList.find((b) => b.id === a.businessId);
    }

    if (business) {
      if (!accountsData[businessId]) {
        accountsData[businessId] = {
          businessDetails: business,
          accounts: [],
          totalAmount: 0
        };
      }

      accountsData[businessId].accounts.push(a);
      accountsData[businessId].totalAmount =
        accountsData[businessId].totalAmount + parseFloat(a.availableBalance);
    }
  });

  return accountsData;
}

export const dummyBusinessId = 'bus-per-001';
